import React, { useEffect, useState } from 'react';
import { menu_list } from '../../resources/menulist';
import MenuItem from './MenuItem';
import { useLocation } from 'react-router-dom';

function HeaderNav(props) {
    const [ menus, getMenus ] = useState(menu_list)
    const [ isShowing, setShowing ] = useState(false)
    const [ currentRoute, setCurrentRoute ] = useState("");

    const location = useLocation()

    const showMenu = () => {
        setShowing(!isShowing)
    }

    useEffect(() => {
        let initRoute = menu_list.find(x => x.route === location.pathname)

        if (initRoute) {
            setCurrentRoute(initRoute.id)
        }

        if (!initRoute) {
            menu_list.forEach(p => {
                if (p.child.find(c => c.route === location.pathname)) {
                    setCurrentRoute(p.id)
                }
            });
        }
    }, [])

    return (
        <div className="header-nav animate-dropdown">
            <div className="container">
            <div className="yamm navbar navbar-default" role="navigation">
                <div className="navbar-header">
                    <button
                        data-target="#mc-horizontal-menu-collapse"
                        data-toggle="collapse"
                        className="navbar-toggle collapsed"
                        type="button"
                        onClick={showMenu}
                    >
                    <span className="sr-only">Toggle navigation</span> <span className="icon-bar"></span> <span className="icon-bar"></span> <span className="icon-bar"></span> </button>
                </div>
                <div className="nav-bg-class">
                <div className={"navbar-collapse collapse" + (isShowing ? " in" : "")} id="mc-horizontal-menu-collapse">
                    <div className="nav-outer">
                    <ul
                        className="nav navbar-nav"
                        onMouseOver={() => setShowing(true)}
                        onMouseOut={() => setShowing(false)}
                    >
                        { menus && menus.map((item, index) =>
                        (item.role === "user" && !props.token) || (item.role === "client" && props.token) ? "" :
                        <MenuItem
                            token={props.token}
                            key={index}
                            {...item}
                            setShowing={setShowing}
                            currentRoute={currentRoute}
                            setCurrentRoute={setCurrentRoute}
                        />) }
                    </ul>
                    {/* <!-- /.navbar-nav --> */}
                    <div className="clearfix"></div>
                    </div>
                    {/* <!-- /.nav-outer -->  */}
                </div>
                {/* <!-- /.navbar-collapse -->  */}
                
                </div>
                {/* <!-- /.nav-bg-class -->  */}
            </div>
            {/* <!-- /.navbar-default -->  */}
            </div>
            {/* <!-- /.container-class -->  */}
            
        </div>
    );
}

export default HeaderNav;