import client from './client'

const getFeedbacks = () => client.get("/feedbacks", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const getFeedback = (id) => client.get("/feedbacks/" + id, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const createFeedback = (feedback) => client.post("/feedbacks", feedback)
const deleteAllFeedback = () => client.delete("/feedbacks", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteMultiFeedbacks = (feedbacks) => client.post("/feedbacks/delete-feedbacks", feedbacks, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const updateMultiFeedbackStatus = (data) => client.post("/feedbacks/update-status", data, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const exportedResult = {
    getFeedbacks,
    getFeedback,
    createFeedback,
    deleteAllFeedback,
    deleteMultiFeedbacks,
    updateMultiFeedbackStatus
}

export default exportedResult;
