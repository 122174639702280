import React, { useEffect, useMemo, useState } from 'react'
import Loading from '../loading/Loading';
import { useAPI } from '../../hooks/useAPI'
import feedbackAPI from '../../api/feedback'
import MaterialReactTable from 'material-react-table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FeedbackList(props) {
    const [ isLoading, setIsLoading ] = useState(false)
    const [ feedbacks, setFeedbacks ] = useState([])
    const getFeedbacks = useAPI(feedbackAPI.getFeedbacks)
    const deleteFeedbacks = useAPI(feedbackAPI.deleteMultiFeedbacks)
    const updateFeedbacksStatus = useAPI(feedbackAPI.updateMultiFeedbackStatus)

    const [rowSelection, setRowSelection] = useState({});
    
    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: '#',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        },
        {
            accessorKey: 'name',
            header: 'Tên',
            size: 100
        },
        {
            accessorKey: 'email',
            header: 'Email',
            size: 100
        },
        {
            accessorKey: 'phoneNumber',
            header: 'Số điện thoại',
            size: 10
        },
        // {
        //     accessorKey: 'subject',
        //     header: 'Subject',
        //     size: 150
        // },
        {
            accessorKey: 'message',
            header: 'Phản hồi',
            size: 500
        },
        {
            accessorKey: 'status',
            header: "Trạng thái",
            size: 10,
            Cell: ({ cell }) => (
                <span className={`label label-${cell.getValue() === '1' ? 'success' : 'warning'}`}>{cell.getValue() === '1' ? 'Đã xử lý' : 'Chờ xử lý'}</span>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        }
    ], [])

    useEffect(() => {
        collectFeedbacks()

        document.title = props.title
    }, [])

    const collectFeedbacks = () => {
        setIsLoading(true)
        getFeedbacks.request()
        .then(res => {
            let feedbacks = res.result.map((item, i) => ({...item, index: i + 1}))
            setFeedbacks(feedbacks)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const deleteHandler = () => {
        if (!window.confirm("Xác nhận xoá các phản hồi?")) {
            return;
        }

        deleteFeedbacks.request(Object.keys(rowSelection))
        .then(res => {
            if (res.status) {
                toast.success("Xoá phản hồi thành công!", { position: toast.POSITION.TOP_RIGHT })
                collectFeedbacks()
                setRowSelection({})
            }
            else {
                toast.error("Có lỗi trong quá trình xoá phản hồi. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    const updateStatusHandler = (status) => {
        if (!window.confirm("Xác nhận cập nhật trạng thái của phản hồi?")) {
            return;
        }

        updateFeedbacksStatus.request({ feedbacks: Object.keys(rowSelection), status: status })
        .then(res => {
            if (res.status) {
                toast.success("Cập nhật các phản hồi thành công!", { position: toast.POSITION.TOP_RIGHT })
                collectFeedbacks()
                setRowSelection({})
            }
            else {
                toast.error("Có lỗi trong quá trình cập nhật. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            <li className='active'>Danh sách phản hồi</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
            { isLoading && <Loading/> }
            <div className="body-content">
                <div className='container'>
                    <div className='row m-b-20'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4' style={{textAlign: "right"}}>
                            <button className='btn btn-danger' onClick={deleteHandler}>Xoá</button>
                            <button className='btn btn-success m-l-20' onClick={() => updateStatusHandler(1)}>Đã xử lý</button>
                            <button className='btn btn-warning m-l-20' onClick={() => updateStatusHandler(0)}>Chờ xử lý</button>
                        </div>
                    </div>
                    <div className='row'>
                        <MaterialReactTable
                            columns={columns}
                            data={feedbacks}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [5, 10, 15, 20],
                                showFirstButton: false,
                                showLastButton: false,
                            }}
                            enableRowSelection={true}
                            enableSorting={true}
                            enableBottomToolbar={true}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableHeadCellProps={{
                                sx: {
                                    fontSize: '14px',
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    lineHeight: '30px'
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    fontSize: '14px',
                                },
                            }}
                            getRowId={(row) => row._id} //give each row a more useful id
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ rowSelection }} //pass our managed row selection state to the table to use
                        />
                    </div>
                </div>
            </div>
            </>
    )
}

export default FeedbackList;
