export const menu_list = [
    {
        id: "home",
        parent: "Trang Chủ",
        child: [],
        image: "",
        type: "container",
        mega_menu: true,
        route: "/"
    },
    {
        id: "services",
        parent: "Dịch vụ",
        child: [],
        image: "",
        type: "pages",
        mega_menu: false,
        route: "/category"
    },
    {
        id: "manage_models",
        parent: "Quản lí",
        child: [
            {
                id: "price_list",
                name: "Bảng giá",
                route: "/price-list",
                role: "user"
            },
            {
                id: "feedbacks",
                name: "Danh sách phản hồi",
                route: "/feedbacks",
                role: "user"
            },
            {
                id: "repairs",
                name: "Danh sách loại sửa chữa",
                route: "/repairs",
                role: "user"
            },
            {
                id: "brands",
                name: "Danh sách hãng",
                route: "/brands",
                role: "user"
            },
            {
                id: "users",
                name: "Danh sách người dùng",
                route: "/users",
                role: "admin"
            }
        ],
        image: "",
        type: "pages",
        mega_menu: false,
        role: "user"
    },
    {
        id: "tips_faq",
        parent: "Các câu hỏi thường gặp",
        child: [],
        image: "",
        type: "pages",
        mega_menu: false,
        route: "/faq"
    },
    {
        id: "contact",
        parent: "Liên hệ",
        child: [],
        image: "",
        type: "pages",
        mega_menu: false,
        route: "/contact",
        role: "client"
    }
]
