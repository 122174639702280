import { useState } from 'react';

function useToken(props) {
    const getToken = () => {
        const tokenString = sessionStorage.getItem("token")
        const userToken = JSON.parse(tokenString)
        return userToken
    }

    const getUsernameSession = () => {
        const usernameString = sessionStorage.getItem("username")
        const username = JSON.parse(usernameString)
        return username
    }

    const getRoleSession = () => {
        const roleString = sessionStorage.getItem("role")
        const role = JSON.parse(roleString)
        return role
    }

    const getRefreshToken = () => {
        const refreshTokenString = sessionStorage.getItem("refreshToken")
        const refreshToken = JSON.parse(refreshTokenString)
        return refreshToken
    }

    const [ token, setToken ] = useState(getToken())
    const [ usernameSession, setUsernameSession ] = useState(getUsernameSession())
    const [ roleSession, setRoleSession ] = useState(getRoleSession())
    const [ refreshToken, setRefreshToken ] = useState(getRefreshToken())

    const saveToken = (userToken) => {
        sessionStorage.setItem('token', JSON.stringify(userToken))
        setToken(userToken)
    }

    const saveUsernameSession = (username) => {
        sessionStorage.setItem('username', JSON.stringify(username))
        setUsernameSession(username)
    }

    const saveRoleSession = (role) => {
        sessionStorage.setItem('role', JSON.stringify(role))
        setRoleSession(role)
    }

    const saveRefreshToken = (refreshToken) => {
        sessionStorage.setItem('refreshToken', JSON.stringify(refreshToken))
        setRefreshToken(refreshToken)
    }

    const removeToken = () => {
        sessionStorage.removeItem('token')
        setToken(getToken())
    }

    const removeUsernameSession = () => {
        sessionStorage.removeItem('username')
        setToken(getUsernameSession())
    }

    const removeRoleSession = () => {
        sessionStorage.removeItem('role')
        setToken(getRoleSession())
    }

    const removeRefreshToken = () => {
        sessionStorage.removeItem('refreshToken')
        setRefreshToken(getRefreshToken())
    }

    return {
        //token
        setToken: saveToken,
        token,
        removeToken,
        //username
        setUsernameSession: saveUsernameSession,
        usernameSession,
        removeUsernameSession,
        //role
        setRoleSession: saveRoleSession,
        roleSession,
        removeRoleSession,
        //refresh-token
        setRefreshToken: saveRefreshToken,
        refreshToken,
        removeRefreshToken
    };
}

export default useToken;