import { useState } from "react"
import user from '../api/user'
import useToken from "../auth/useToken"

// https://betterprogramming.pub/clean-api-call-with-react-hooks-3bd6438a375a
export const useAPI = (apiFunc) => {
    const [ loading, setLoading ] = useState(false)
    const { setToken } = useToken()

    const request = async (...args) => {
        setLoading(true)
        try {
            const result = await apiFunc(...args)
            return { status: true, result: result.data }
        }
        catch(err) {
            if (err.response.status === 401) {
                let getRefreshToken = await user.RefreshToken()
                setToken(getRefreshToken.data.accessToken)
                const result = await apiFunc(...args)
                return { status: true, result: result.data }
            }
            return { status: false, result: (err.response.data || "Unexpected Error!") }
        }
        finally {
            setLoading(false)
        }
    }

    return {
        loading,
        request
    }
}
