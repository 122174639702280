import React, { useState } from 'react';

function TopNavigation(props) {
    return (
        <div className="side-menu animate-dropdown">
            <div className="head">
                <i className="icon fa fa-align-justify fa-fw" onClick={() => { props.setOpenMenu(!props.openMenu); }}></i>
                { (props.openMenu) && "Categories" }
            </div>
            <nav className="yamm megamenu-horizontal" style={{ display: (!props.openMenu) && 'none' }}>
                <ul className="nav">
                    <li
                        className={"dropdown menu-item"}
                    >
                        <a href="!#" className="dropdown-toggle" data-toggle="dropdown" id="clothing" >
                            <i className="icon fa fa-shopping-bag" aria-hidden="true"></i>Categoris
                        </a>
                        <a href="!#" className="dropdown-toggle" data-toggle="dropdown" id="clothing" >
                            <i className="icon fa fa-shopping-bag" aria-hidden="true"></i>Products
                        </a>
                        {/* <ul className="dropdown-menu pages">
                            <li className="yamm-content">
                                <div className="row">
                                    <div className="col-sm-12 col-md-3">
                                        <ul className="links list-unstyled">
                                        <li><a href="!#">All Products</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ul> */}
                        <a href="!#" className="dropdown-toggle" data-toggle="dropdown" id="clothing" >
                            <i className="icon fa fa-shopping-bag" aria-hidden="true"></i>Users
                        </a>
                        <a href="!#" className="dropdown-toggle" data-toggle="dropdown" id="clothing" >
                            <i className="icon fa fa-shopping-bag" aria-hidden="true"></i>Faq
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default TopNavigation;