import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../loading/Loading';
import { useAPI } from '../../hooks/useAPI'
import feedbackAPI from '../../api/feedback'
import { ErrorList } from '../../resources/Error';
import validate from '../../utils/Validate'

function Contact(props) {
    const initFeedbackState = { name: "", email: "", phoneNumber: "", subject: "", message: "" }
    const [ feedback, setFeedback ] = useState(initFeedbackState)
    const [ isLoading, setIsLoading ] = useState(false)

    const createFeedback = useAPI(feedbackAPI.createFeedback)

    useEffect(() => {
        document.title = props.title
    }, [])

    const handleContact = (e) => {
        setFeedback({ ...feedback, [e.target.name]: e.target.value })
    }

    const clearFrm = () => {
        setFeedback(initFeedbackState)
    }
    
    const sendMessage = () => {
        setIsLoading(true)

        feedback.subject = "Feedback"

        if (feedback.email.length > 0 && !validate.validateEmail(feedback.email)) {
            setIsLoading(false)
            toast.error(ErrorList["invalid_email"], { position: toast.POSITION.TOP_RIGHT})
            return;
        }

        if (!validate.validatePhoneNumber(feedback.phoneNumber)) {
            setIsLoading(false)
            toast.error(ErrorList["invalid_phone_number"], { position: toast.POSITION.TOP_RIGHT})
            return;
        }

        createFeedback.request(feedback)
        .then(res => {
            if (res.status) {
                toast.success("Gửi phản hồi thành công", { position: toast.POSITION.TOP_RIGHT})
                setFeedback(initFeedbackState)
            }
            else {
                toast.error(ErrorList[res.result.message], { position: toast.POSITION.TOP_RIGHT})
            }
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    return (
        <>
            { isLoading && <Loading/> }
            <ToastContainer/>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            {/* <li><a href="!#">Home</a></li> */}
                            <li className='active'>Liên hệ</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="body-content">
                <div className="container">
                    <div className="contact-page">
                        <div className="row">
                            <div className="col-md-8 contact-form">
                                <div className="col-md-12 contact-title">
                                    <h4>Liên hệ</h4>
                                </div>
                                <div className="col-md-12 ">
                                    <form className="register-form">
                                        <label className="col-md-3 info-title" htmlFor="nameId">Họ tên bạn <span>*</span></label>
                                        <div className="col-md-9 form-group">
                                            <input
                                                type="text"
                                                className="form-control unicase-form-control text-input"
                                                id="nameId"
                                                name="name"
                                                placeholder=""
                                                onChange={handleContact}
                                                value={ feedback.name }
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-12">
                                    <form className="register-form">
                                        <label className="col-md-3 info-title" htmlFor="emailId">Nhập Email của bạn</label>
                                        <div className="col-md-9 form-group">
                                            <input
                                                type="email"
                                                className="form-control unicase-form-control text-input"
                                                id="emailId"
                                                name='email'
                                                placeholder=""
                                                onChange={handleContact}
                                                value={ feedback.email }
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-12">
                                    <form className="register-form">
                                        <label className="col-md-3 info-title" htmlFor="phoneNumberId">Nhập số di động của bạn <span>*</span></label>
                                        <div className="col-md-9 form-group">
                                            <input
                                                type="text"
                                                className="form-control unicase-form-control text-input"
                                                id="phoneNumberId"
                                                name='phoneNumber'
                                                placeholder=""
                                                onChange={handleContact}
                                                value={ feedback.phoneNumber }
                                            />
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-12">
                                    <form className="register-form">
                                        <label className="col-md-3 info-title" htmlFor="messageId">Vấn đề bạn gặp phải <span>*</span></label>
                                        <div className="col-md-9 form-group">
                                            <textarea
                                                className="form-control unicase-form-control"
                                                id="messageId"
                                                name='message'
                                                onChange={handleContact}
                                                value={ feedback.message }
                                            ></textarea>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-12 outer-bottom-small m-t-20">
                                    <div className='col-md-3'></div>
                                    <div className='col-md-9'>
                                        <button className="btn-upper btn btn-primary btn-contact-save" onClick={sendMessage}>Gửi</button>
                                        <button className="btn-upper btn btn-primary btn-contact-clear" onClick={clearFrm}>Xoá</button>
                                    </div>
                                </div>
                                <div className='col-md-12 outer-bottom-small m-t-20'>
                                    <div className='container'>
                                        <p>Mọi đóng góp và thắc mắc vui lòng liên hệ với trung tâm theo hình thức sau</p>
                                        <p>- Liên hệ hotline: 0903 622 393</p>
                                        <p>- Trao đổi qua Fanpage</p>
                                        <p>- Email: myngocmobile650@gmail.com</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 contact-info">
                                {/* <div className="contact-title">
                                    <h4>Information</h4>
                                </div>
                                <div className="clearfix address">
                                    <span className="contact-i"><i className="fa fa-map-marker"></i></span>
                                    <span className="contact-span">ThemesGround, 789 Main rd, Anytown, CA 12345 USA</span>
                                </div>
                                <div className="clearfix phone-no">
                                    <span className="contact-i"><i className="fa fa-mobile"></i></span>
                                    <span className="contact-span">+(888) 123-4567<br />+(888) 456-7890</span>
                                </div>
                                <div className="clearfix email">
                                    <span className="contact-i"><i className="fa fa-envelope"></i></span>
                                    <span className="contact-span"><a href="!#">marazoo@themesground.com</a></span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;