import React, { useEffect, useMemo, useState } from 'react'
import Loading from '../loading/Loading';
import { useAPI } from '../../hooks/useAPI'
import brandAPI from '../../api/brand'
import MaterialReactTable from 'material-react-table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BrandList(props) {
    const initBrand = {
        name: ""
    }

    const [ isLoading, setIsLoading ] = useState(false)
    const [ brands, setBrands ] = useState([])
    const getBrand = useAPI(brandAPI.getBrand)
    const getBrands = useAPI(brandAPI.getBrands)
    const deleteBrands = useAPI(brandAPI.deleteMultiBrands)
    const updateBrand = useAPI(brandAPI.updateBrand)
    const createBrand = useAPI(brandAPI.createBrand)

    const [rowSelection, setRowSelection] = useState({});
    const [ showDialog, setShowDialog ] = useState(false)
    const [ brand, setBrand ] = useState(initBrand)

    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: '#',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        },
        {
            accessorKey: 'name',
            header: 'Tên',
            accessorFn: (row) => <a onClick={() => updateBrandHandler(row._id)} style={{cursor: "pointer"}}>{row.name}</a>,
            size: 1000
        }
    ], [])

    useEffect(() => {
        collectBrands()

        document.title = props.title
    }, [])

    const collectBrands = () => {
        setIsLoading(true)
        getBrands.request()
        .then(res => {
            let brands = res.result.map((item, i) => ({...item, index: i + 1}))
            setBrands(brands)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const deleteHandler = () => {
        if (!window.confirm("Xác nhận xoá (các) Model?")) {
            return;
        }

        deleteBrands.request(Object.keys(rowSelection))
        .then(res => {
            if (res.status) {
                toast.success("Xoá (các) Model thành công!", { position: toast.POSITION.TOP_RIGHT })
                collectBrands()
                setRowSelection({})
            }
            else {
                toast.error("Có lỗi trong quá trình xoá (các) Model. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    const updateBrandHandler = (brand_id) => {
        handleDialog()
        getBrand.request(brand_id)
        .then(res => {
            if (res.status) {
                setBrand(res.result)
            }
            else {
                toast.error("Có lỗi trong quá trình lấy thông tin Model.", {position: toast.POSITION.TOP_RIGHT})
            }
        })
    }

    const handleDialog = () => {
        setShowDialog((prevState) => {
            if (prevState) {
                setBrand(initBrand)
            }
            return !prevState
        })
    }


    const handleCreateBrand = (e) => {
        const fieldName = e.target.name
        const fieldValue = e.target.value
        setBrand({ ...brand, [ fieldName ]: fieldValue })
    }

    const saveBrand = () => {
        if (!brand._id) {
            createBrand.request(brand)
            .then(res => {
                if (res.status) {
                    handleDialog()
                    collectBrands()
                    toast.success("Thêm Model thành công", { position: toast.POSITION.TOP_RIGHT })
                }
                else {
                    toast.error("Có lỗi trong quá trình tạo Model", { position: toast.POSITION.TOP_RIGHT })
                }
            })
        }
        else {
            updateBrand.request(brand._id, {name: brand.name})
            .then(res => {
                if(res.status) {
                    handleDialog()
                    collectBrands()
                    toast.success("Cập nhật thông tin Model thành công.")
                }
                else {
                    toast.error("Có lỗi trong quá trình cập nhật thông tin Model.")
                }
            })
        }
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            <li className='active'>Danh sách Model</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
            { isLoading && <Loading/> }
            <div className="body-content">
                <div className='container'>
                    <div className='row'>
                        <div className='col col-md-6'>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={handleDialog}>
                            Thêm Model
                        </button>
                    </div>
                    </div>
                    <div className='row m-b-20'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4' style={{textAlign: "right"}}>
                            <button className='btn btn-danger' onClick={deleteHandler}>Xoá</button>
                        </div>
                    </div>
                    <div className='row'>
                        <MaterialReactTable
                            columns={columns}
                            data={brands}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [5, 10, 15, 20],
                                showFirstButton: false,
                                showLastButton: false,
                            }}
                            enableRowSelection={true}
                            enableSorting={true}
                            enableBottomToolbar={true}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableHeadCellProps={{
                                sx: {
                                    fontSize: '14px',
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    lineHeight: '30px'
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    fontSize: '14px',
                                },
                            }}
                            getRowId={(row) => row._id} //give each row a more useful id
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ rowSelection }} //pass our managed row selection state to the table to use
                        />
                    </div>
                </div>
            </div>
            {/* <!-- Modal --> */}
            <div className="modal" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: showDialog ? "inline-grid" : "none"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{brand._id ? "Sửa " : "Thêm"} Model</h5>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="inputName" className="col-sm-2 col-form-label">Tên</label>
                                <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputName" placeholder="" name="name" onChange={handleCreateBrand} value={brand?.name}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDialog}>Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={saveBrand}>Lưu</button>
                    </div>
                    </div>
                </div>
            </div>
            </>
    )
}

export default BrandList;
