import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import useToken from '../../auth/useToken';

function MenuItem(props) {
    const [ isOpen, setIsOpen ] = useState();
    const [ isHover, setHover ] = useState(false)
    const {
        roleSession
    } = useToken()

    useEffect(() => {
    }, []);

    const handleTouch = (e) => {

        setHover(prev => {
            return !prev
        })
    }

    return (
        <li
            className=  {"dropdown" + (props.mega_menu ? " mega-menu" : "") +
                        (props.currentRoute === props.id ? " active" : "") +
                        (isHover ? " hover" : "")}
        >
            {
            props.route ? 
            <NavLink
                to={props.route}
                className="dropdown-toggle"
                onClick={() => props.setCurrentRoute(props.id)}
                onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)}
            >{props.parent}</NavLink>
            :
            <a
                onClick={() => false}
                className='dropdown-toggle'
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                onTouchStart={handleTouch}
            >{props.parent}</a>
            }
            { props.child.length > 0 ?
                <ul
                    className={"dropdown-menu " + props.type}
                    onMouseOver={() => setHover(true)}
                    onMouseOut={() => setHover(false)}
                >
                <li>
                    <div className="yamm-content">
                        <div className="row">
                            { props.mega_menu === true ? props.child && props.child.map((item, index) => 
                            <div className="col-xs-12 col-menu" key={index}>
                                { item.name ? <h2 className="title">{item.name}</h2> : "" }
                                <ul
                                    className="links"
                                >
                                    { item.child && item.child.map((item_li, index_li) => 
                                        <li key={ index_li }>
                                            <a
                                                href="!#"
                                                onSelect={(e) => {
                                                    e.preventDefault()
                                                    setIsOpen(false);
                                                }}
                                            >{ item_li }</a>
                                        </li>
                                    ) }
                                </ul>
                            </div>
                            ) : 
                            <div className='col-xs-12 col-menu'>
                                <ul className='links'>
                                    {props.child && props.child.map((item, index) => ((roleSession === 1) || (item.role === "user" && roleSession === 0)) ?
                                    <li key={index}><NavLink to={item.route} onClick={() => props.setCurrentRoute(props.id)}>{item.name}</NavLink></li> : ""
                                    )}
                                </ul>
                            </div>
                            }
                            { props.image !== "" ? 
                            <div className="col-xs-12 col-sm-6 col-md-4 col-menu banner-image"> <img className="img-responsive" src={ props.image } alt=""/> </div> :
                            ""}
                        </div>
                    </div>
                </li>
            </ul> : ""}
        </li>
    );
}

export default MenuItem;
