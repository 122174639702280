import React, { useEffect, useState } from 'react';
import { useAPI } from '../../../hooks/useAPI';
import UserAPI from '../../../api/user'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UpdateProfile(props) {
    const [ updatePwd, setUpdatePwd ] = useState(false)
    const [ userData, setUserData ] = useState({})

    const getUserData = useAPI(UserAPI.getUser)
    const updateUserInfo = useAPI(UserAPI.UpdateUserInfo)
    const updateUserPassword = useAPI(UserAPI.UpdatePassword)

    useEffect(() => {
        document.title = props.title

        getUserData.request({username: props.usernameSession})
        .then(res => {
            if (res.status) {
                setUserData(res.result)
            }
        })
    }, [])

    const setUpdatePwHandler = (e) => {
        setUpdatePwd(!updatePwd)
    }

    const handleCollectUserData = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    const updateUser = () => {
        updateUserInfo.request(userData)
        .then(res => {
            if (res.status) {
                toast.success("Cập nhật thông tin thành công", { position: toast.POSITION.TOP_RIGHT })
            }
            else {
                toast.error("Đã xảy ra lỗi khi cập nhật thông tin cá nhân. (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })

        if (updatePwd) {
            updateUserPassword.request(userData)
            .then(res => {
                if (res.status) {
                    toast.success("Cập nhật mật khẩu thành công", { position: toast.POSITION.TOP_RIGHT })
                }
                else {
                    toast.error("Đã xảy ra lỗi khi cập nhật mật khẩu. (" + res.result.message + ")", { position: toast.POSITION.TOP_RIGHT })
                }
            })
        }
    }

    return (
        <div>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            {/* <li><a href="home.html">Home</a></li> */}
                            <li className='active'>Cập nhật thông tin cá nhân</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
            <div className="body-content">
                <div className="container">
                    <div className="sign-in-page">
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                {/* <div className="avatar"><img src="assets/images/testimonials/member1.png" alt="123"/></div>
                                <div className='clients_author'><button type='button' className='btn-upper btn btn-primary'>Thêm hình ảnh</button></div> */}
                            </div>
                            <div className="col-md-6 col-sm-6 create-new-account">
                                <h4 className="checkout-subtitle">Cập nhật thông tin</h4>
                                {/* <p className="text title-tag-line">Create your new account.</p> */}
                                {/* <div className="form-group">
                                    <label className="info-title" htmlFor="email">Email Address <span>*</span></label>
                                    <input type="email" className="form-control unicase-form-control text-input" id="email" name="register-email" /> */}
                                    {/* <p className='err-msg'>{(isAction === "register") && (!isValidEmail) && isErr === "invalid_email" && ErrorList[isErr]}</p> */}
                                {/* </div> */}
                                {/* <div className="form-group">
                                    <label className="info-title" htmlFor="name">Tên đăng nhập <span className="require">*</span></label>
                                    <input type="text" className="form-control unicase-form-control text-input" id="name" name="username" />
                                    <p className='err-msg'></p>
                                </div> */}
                                <div className="form-group">
                                    <label className="info-title" htmlFor="phone">Số điện thoại <span className="require">*</span></label>
                                    <input
                                        type="number"
                                        className="form-control unicase-form-control text-input"
                                        id="phone"
                                        name="phoneNumber"
                                        onChange={handleCollectUserData}
                                        value={userData.phoneNumber}
                                    />
                                    <p className='err-msg'></p>
                                </div>
                                <div className='form-group'>
                                    <label className="form-check-label">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={updatePwd}
                                            onChange={setUpdatePwHandler}
                                        /> Cập nhật mật khẩu
                                    </label>
                                </div>
                                {(updatePwd) ? <>
                                <div className="form-group">
                                    <label className="info-title" htmlFor="oldPassword">Mật khẩu cũ <span className="require">*</span></label>
                                    <input
                                        type="password"
                                        className="form-control unicase-form-control text-input"
                                        id="oldPassword"
                                        name="oldPassword"
                                        onChange={handleCollectUserData}
                                    />
                                    {/* <p className='err-msg'>{(isAction === "register") && (!isValidPassword) && isErr === "invalid_password" && ErrorList[isErr]}</p> */}
                                </div>
                                <div className="form-group">
                                    <label className="info-title" htmlFor="newPassword">Mật khẩu mới <span className="require">*</span></label>
                                    <input
                                        type="password"
                                        className="form-control unicase-form-control text-input"
                                        id="newPassword"
                                        name="newPassword"
                                        onChange={handleCollectUserData}
                                    />
                                    {/* <p className='err-msg'>{(isAction === "register") && (!isValidPassword) && isErr === "invalid_password" && ErrorList[isErr]}</p> */}
                                </div>
                                <div className="form-group">
                                    <label className="info-title" htmlFor="confirmPassword">Xác nhận mật khẩu <span className="require">*</span></label>
                                    <input
                                        type="password"
                                        className="form-control unicase-form-control text-input"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        onChange={handleCollectUserData}
                                    />
                                    {/* <p className='err-msg'>{(isAction === "register") && (!isPwdMatch) && isErr === "not_match_pwd" && ErrorList[isErr]}</p> */}
                                </div>
                                </>: ""}
                                <button type="button" className="btn-upper btn btn-primary checkout-page-button" onClick={updateUser}>Lưu</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateProfile;