import React from 'react';
import HeaderNav from './HeaderNav';
import HeaderTop from './HeaderTop';
import MainHeader from './MainHeader';

function Header(props) {
    return (
        <header className='header-style-1'>
            <HeaderTop
                token={props.token}
                removeToken={props.removeToken}
                removeRefreshToken={props.removeRefreshToken}
                usernameSession={props.usernameSession}
                removeUsernameSession={props.removeUsernameSession}
                removeRoleSession={props.removeRoleSession}
            />
            <MainHeader
                cart={props.cart}
                setCart={props.setCart}
                totalPrice={props.totalPrice}
                deleteCartItem={props.deleteCartItem}
            />
            <HeaderNav token={props.token}/>
        </header>
    );
}

export default Header;