import client from './client'

const config = { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } }
const Login = (user) => client.post("/users/login", user)
const RefreshToken = () => client.post("/users/refresh-token", {refreshToken: JSON.parse(sessionStorage.getItem("refreshToken"))}, config)
const UpdateUserInfo = (user) => client.post("/users/update-userinfo", user, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const UpdatePassword = (user) => client.post("/users/update-password", user, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const getUser = (username) => client.post("/users/user", username, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const createUser = (user) => client.post("/users", user, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

//
const getAllUsers = () => client.get("/users", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const updateUser = (user) => client.post("/users/update-userinfo", user, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteUsers = (users) => client.post("/users/delete", users, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const result = {
    Login,
    RefreshToken,
    UpdateUserInfo,
    UpdatePassword,
    getUser,
    createUser,

    getAllUsers,
    updateUser,
    deleteUsers
}
export default result;
