import React, { useEffect, useState } from 'react';
import { ErrorList } from '../../../resources/Error';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAPI } from '../../../hooks/useAPI';
import userAPI from '../../../api/user'
import Loading from '../../loading/Loading';
import { useNavigate } from 'react-router-dom';
import validate from '../../../utils/Validate'

function Login(props) {
    let navigate = useNavigate()

    const initUserLogin = { "login-username": "", "login-password": "" }
    const initUserRegister = { "register-username": "", "register-phone": "", "register-password": "", "register-confirm-pwd": "" }

    const getUserLogin = useAPI(userAPI.Login)
    const createUser = useAPI(userAPI.createUser)

    const [userLogin, setUserLogin] = useState(initUserLogin)
    const [userRegister, setUserRegister] = useState(initUserRegister)
    const [isValidForm, setValidForm] = useState(false)
    const [isErr, setErr] = useState("")
    const [isAction, setAction] = useState("")
    const [form, setForm] = useState("login")
    const [isShowPW, setShowPW] = useState(false)
    const [isShowConfirmPW, setShowConfirmPW] = useState(false)

    useEffect(() => {
        document.title = props.title
    }, [])

    const handleLogin = (e) => {
        setAction("login")
        const field_name = e.target.name
        const field_value = e.target.value
        setUserLogin({ ...userLogin, [field_name]: field_value });
    }
    const handleRegister = (e) => {
        setAction("register")
        const field_name = e.target.name
        const field_value = e.target.value

        if (field_name === "register-phone") {
            if (!validate.validatePhoneNumber(field_value)) {
                setValidForm(false)
                setErr("invalid_phone_number")
                return
            }
        }
        else if (field_name === "register-password") {
            if (!validate.validatePassword(field_value)) {
                setValidForm(false)
                setErr("invalid_password")
                return
            }
        }
        else if (field_name === "register-confirm-pwd") {
            if (!validate.checkPwdMatch(userRegister["register-password"], field_value)) {
                setValidForm(false)
                setErr("not_match_pwd")
                return
            }
        }
        setUserRegister({ ...userRegister, [field_name]: field_value })
        setValidForm(true)
        setErr("")
    }
    const login = (e) => {
        e.preventDefault()
        const user = {}
        user.username = userLogin["login-username"]
        user.password = userLogin["login-password"]

        getUserLogin.request(user)
        .then(res => {
            if (res.status) {
                props.setToken(res.result.token)
                props.setRefreshToken(res.result.refreshToken)
                props.setUsernameSession(res.result.user.username)
                props.setRoleSession(res.result.user.role)
                navigate("/")
            } else {
                toast.error(res.result.message, { position: toast.POSITION.TOP_RIGHT })
            }
        })

    }
    const register = (e) => {
        e.preventDefault()
        // Check all field is filled
        let check_fill = Object.values(userRegister).every(item => item !== "")
        if (!check_fill) {
            toast.warning("All fields need to be filled in")
            return;
        }

        const user = {}

        user.username = userRegister["register-username"]
        user.password = userRegister["register-password"]
        user.phoneNumber = userRegister["register-phone"]
        user.confirmPassword = userRegister["register-confirm-pwd"]

        createUser.request(user)
        .then(res => {
            if (res.status) {
                toast.success("Tạo tài khoản thành công. Vui lòng đăng nhập!", { position: toast.POSITION.TOP_RIGHT })
            }
            else {
                toast.error("Có lỗi trong quá trình tạo tài khoản. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })

        setForm("login")
        setUserLogin(initUserLogin)
    }

    const handleEnter = (e) => {
        if (e.key === "Enter") {
            login(e);
        }
    }

    const changeFrm = (name) => {
        setForm(name)
        setUserLogin(initUserLogin)
        setUserRegister(initUserRegister)
    }

    const handleShowPW = (type) => {
        if (type === "pw") {
            setShowPW(prev => {
                return !prev
            })
        }
        else if (type === "confirm-pw") {
            setShowConfirmPW(prev => {
                return !prev
            })
        }
    }

    const loginRender = () => {
        return (
            <div className="col-md-6 col-sm-6 sign-in">
                <h4 className="">Đăng nhập</h4>
                {/* <p className="">Hello, Welcome to your account.</p> */}
                {/* <div className="form-group">
                    <label className="info-title" htmlFor="login-email">Email Address <span>*</span></label>
                    <input type="email" className="form-control unicase-form-control text-input" id="login-email" required onChange={handleLogin} name="login-email"/>
                    <p className='err-msg'>{(isAction === "login") && (!isValidEmail) && isErr === "invalid_email" && ErrorList[isErr]}</p>
                </div> */}
                <div className="form-group">
                    <label className="info-title" htmlFor="login-username">Tên đăng nhập <span className="require">*</span></label>
                    <input
                        type="text"
                        className="form-control unicase-form-control text-input"
                        id="login-username"
                        required
                        onChange={handleLogin}
                        onKeyDown={handleEnter}
                        name="login-username"
                        value={userLogin["login-username"]}
                    />
                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="login-password">Mật khẩu <span className="require">*</span></label>
                    <input
                        type="password"
                        className="form-control unicase-form-control text-input"
                        id="login-password"
                        required
                        onChange={handleLogin}
                        onKeyDown={handleEnter}
                        name="login-password"
                        value={userLogin["login-password"]}
                    />
                </div>
                {/* <div className="radio outer-xs">
                    <label>
                        <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />Remember me!
                    </label>
                    <a href="#" className="forgot-password pull-right">Forgot your Password?</a>
                </div> */}
                <div className="form-group">
                    <button type="button" className="btn-upper btn btn-primary checkout-page-button" onClick={login}>Đăng nhập</button>
                </div>
                <div className='form-group'>
                    Bạn chưa có tài khoản? <a onClick={() => changeFrm('register')} style={{cursor: 'pointer'}}>Tạo tài khoản</a>
                </div>
            </div>
        )
    }

    const registerRender = () => {
        return (
            <div className="col-md-6 col-sm-6 create-new-account">
                <h4 className="checkout-subtitle">Tạo tài khoản</h4>
                <div className="form-group">
                    <label className="info-title" htmlFor="name">Tên đăng nhập <span className="require">*</span></label>
                    <input
                        type="text"
                        className="form-control unicase-form-control text-input"
                        id="name"
                        name="register-username"
                        onChange={handleRegister}
                        value={userRegister["register-username"]}
                    />
                    <p className='err-msg'></p>
                </div>
                <div className="form-group">
                    <label className="info-title" htmlFor="phone">Số điện thoại <span className="require">*</span></label>
                    <input
                        type="text"
                        className="form-control unicase-form-control text-input"
                        id="phone"
                        name="register-phone"
                        onChange={handleRegister}
                    />
                    <p className='err-msg'>{(isAction === "register") && (!isValidForm) && isErr === "invalid_phone_number" && ErrorList[isErr]}</p>
                </div>
                <div className="form-group show-pw">
                    <label className="info-title" htmlFor="password">Mật khẩu <span className="require">*</span></label>
                    <input
                        type={isShowPW ? "text" : "password"}
                        className="form-control unicase-form-control text-input"
                        id="password" name="register-password"
                        onChange={handleRegister}
                    />
                    <i className={'fa fa-eye' + (isShowPW ? "-slash" : "")} onClick={() => handleShowPW("pw")}></i>
                    <p className='err-msg'>{(isAction === "register") && (!isValidForm) && isErr === "invalid_password" && ErrorList[isErr]}</p>
                </div>
                <div className="form-group show-pw">
                    <label className="info-title" htmlFor="confirmPassword">Xác nhận mật khẩu <span className="require">*</span></label>
                    <input
                        type={isShowConfirmPW ? "text" : "password"}
                        className="form-control unicase-form-control text-input"
                        id="confirmPassword"
                        name="register-confirm-pwd"
                        onChange={handleRegister}
                    />
                    <i className={'fa fa-eye' + (isShowConfirmPW ? "-slash" : "")} onClick={() => handleShowPW("confirm-pw")}></i>
                    <p className='err-msg'>{(isAction === "register") && (!isValidForm) && isErr === "not_match_pwd" && ErrorList[isErr]}</p>
                </div>
                <div className='form-group'>
                    <button type="button" className="btn-upper btn btn-primary checkout-page-button" onClick={register}>Đăng kí</button>
                </div>
                <div className='form-group'>
                    Bạn đã có tài khoản? <a onClick={() => changeFrm('login')} style={{cursor: 'pointer'}}>Đăng nhập</a>
                </div>
            </div>
        )
    }

    return (
        <div>
            { getUserLogin.loading && <Loading/> }
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            <li><a href="home.html">Trang chủ</a></li>
                            <li className='active'>{form === 'login' ? "Đăng nhập" : (form === 'register' ? 'Đăng kí' : '')}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <ToastContainer />

            <div className="body-content">
                <div className="container">
                    <div className="sign-in-page">
                        <div className="row">
                            { (form === 'login') && loginRender() }
                            
                            { (form === 'register') && registerRender() }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;