import client from "./client"

const getProducts = () => client.get("/products")
const getProduct = (id) => client.get("/products/" + id)
const getProductsOfBrand = (id) => {
    if (id) {
        return client.get("/products/brand/" + id)
    } else {
        return client.get("/products")
    }
}
const getBrands = () => client.get("/brands")
const getRepairs = () => client.get("/repairs", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const postProduct = (product) => client.post("/products", product, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteProduct = (id) => client.delete("/products/" + id, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const updateProduct = (id, product) => client.put("/products/" + id, product, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const exportedObj = {
    getProducts,
    getBrands,
    getRepairs,
    postProduct,
    deleteProduct,
    getProductsOfBrand,
    getProduct,
    updateProduct
}

export default exportedObj;