export const blog_list = [
    {
        "image": "assets/images/blog-post/blog_big_01.jpg",
        "name": "Voluptatem accusantium doloremque laudantium",
        "info": "By Jone Doe &nbsp;|&nbsp; 21 March 2016",
        "description": "Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
        "image": "assets/images/blog-post/blog_big_02.jpg",
        "name": "Voluptatem accusantium doloremque laudantium",
        "info": "By Jone Doe &nbsp;|&nbsp; 21 March 2016",
        "description": "Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
        "image": "assets/images/blog-post/blog_big_03.jpg",
        "name": "Voluptatem accusantium doloremque laudantium",
        "info": "By Jone Doe &nbsp;|&nbsp; 21 March 2016",
        "description": "Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
        "image": "assets/images/blog-post/blog_big_01.jpg",
        "name": "Voluptatem accusantium doloremque laudantium",
        "info": "By Jone Doe &nbsp;|&nbsp; 21 March 2016",
        "description": "Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    },
    {
        "image": "assets/images/blog-post/blog_big_02.jpg",
        "name": "Voluptatem accusantium doloremque laudantium",
        "info": "By Jone Doe &nbsp;|&nbsp; 21 March 2016",
        "description": "Sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem."
    }
]