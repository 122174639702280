import client from './client'

const getRepairs = () => client.get("/repairs")
const getRepair = (id) => client.get("/repairs/" + id)
const createRepair = (repair) => client.post("/repairs", repair, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const updateRepair = (id, repair) => client.put("/repairs/" + id, repair, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteAllRepair = () => client.delete("/repairs", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteMultiRepairs = (repairs) => client.post("/repairs/delete-repairs", repairs, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const exportedResult = {
    getRepairs,
    getRepair,
    createRepair,
    updateRepair,
    deleteAllRepair,
    deleteMultiRepairs
}

export default exportedResult;