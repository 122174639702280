import React, { useEffect, useRef, useState } from 'react'
import { useAPI } from '../../hooks/useAPI'
import productAPI from '../../api/products'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../loading/Loading';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export const RepairTypeCheckBox = (props) => {
	const [isCheck, setIsCheck] = useState(false)

	const setCheckHandler = () => {
		setIsCheck(!isCheck)
	}

	useEffect(() => {
		setIsCheck(props.isCheck)
	}, [props.isCheck])

	useEffect(() => {
		// uncheck -> check
		if (isCheck && !props.isCheck) {
			props.setProduct((prevState) => {
				let tempProduct = {...prevState}
				tempProduct.repair_type.push(props.repair_id)
				return tempProduct
			})
		}
		// check -> uncheck
		if (!isCheck && props.isCheck) {
			props.setProduct((prevState) => {
				let tempProduct = {...prevState}
				let typesUpdated = tempProduct.repair_type.filter(type => {
					return type !== props.repair_id
				})
				return {...prevState, repair_type: typesUpdated}
			})
		}
	}, [isCheck])

	return (
		<div>
			<label className="form-check-label">
				<input type="checkbox" className="form-check-input" value="" checked={isCheck} onChange={setCheckHandler}/> {props.label}
			</label>
		</div>
	)
}

function PriceList(props) {
	const getProduct = useAPI(productAPI.getProduct)
	const getProductsOfBrand = useAPI(productAPI.getProductsOfBrand)
	const getBrands = useAPI(productAPI.getBrands)
	const getRepairs = useAPI(productAPI.getRepairs)

	const postProduct = useAPI(productAPI.postProduct)
	const delProduct = useAPI(productAPI.deleteProduct)
	const updProduct = useAPI(productAPI.updateProduct)

	const [ showDialog, setShowDialog ] = useState(false)
	const [ brand, setBrand ] = useState("")
	const [ products, setProducts ] = useState([])
	const [ brands, setBrands ] = useState([])
	const [ repairs, setRepairs ] = useState([])
	const [ isUpdate, setUpdate ] = useState(false)
	const [editorValue, setEditorValue] = useState('');

	const inputRef = useRef(null);

	const initProduct = {
		name: "",
		price: "",
		brand: "",
		repair_type: [],
		image: null
	}

	const [ product, setProduct ] = useState(initProduct)
	const [ showRecords, setShowRecords ] = useState(false)
	// pagination
	const [ currentPage, setCurrentPage ] = useState(1)
	const [ numPages, setNumPages ] = useState(1)
	const [ btnPrev, setBtnPrev ] = useState(false)
	const [ btnNext, setBtnNext ] = useState(false)
	const [ productsShowing, setProductsShowing ] = useState([])
	const [ records, setRecords ] = useState(10)
	const [ isLoading, setIsLoading ] = useState(false)

	const changePage = (page) => {
		let products_temp = []

		if (page < 1) page = 1
		if (page > numPages) page = numPages
		if (page === 1) {
			setBtnPrev(false)
		} else {
			setBtnPrev(true)
		}

		if (page === numPages) {
			setBtnNext(false)
		} else {
			setBtnNext(true)
		}

		for (let i = (page - 1) * records; i < (page * records) && i < products.length; i++) {
			products_temp.push(products[i])
		}

		setProductsShowing(products_temp)
	}

	const selectPage = (page) => {
		changePage(page)
		setCurrentPage(page)
	}

	const selectRecordsPerPage = (e) => {
		setRecords(e.target.innerHTML)
		setShowRecords(false)
	}

	useEffect(() => {
		if (products.length !== 0) {
			// change products list => rerender
			setNumPages(Math.ceil(products.length / records))
			setCurrentPage(1)
			changePage(1)
		}
	}, [products])

	useEffect(() => {
		if (products.length !== 0) {
			setNumPages(Math.ceil(products.length / records))
			setCurrentPage(1)
			changePage(1)
		}
	}, [ records ])

	useEffect(() => {
		setCurrentPage(1)
		changePage(1)
	}, [numPages])

	useEffect(() => {
		getBrands.request().then(data => {
			setBrands(data.result)
		})
		getRepairs.request().then(data => {
			setRepairs(data.result)
		})

		document.title = props.title
	}, [])

	useEffect(() => {
		setIsLoading(true)
		getProductsOfBrand.request(brand).then(data => {
			setProducts(data.result)
		})
		.finally(() => {
			setIsLoading(false)
		})
	}, [ brand ])

	useEffect(() => {
		if (showDialog) {
			if (!isUpdate) { // create
				setProduct(initProduct)
				setEditorValue('')
			}
		}
		else {
			setUpdate(false)
			inputRef.current.value = null
		}
	}, [ showDialog ])

	const handleDialog = () => {
		setShowDialog((prevState) => {
			return !prevState
		})
	}

	const handleCreateProduct = (e) => {
		const fieldName = e.target.name
		if (fieldName === "image") {
			const fieldValue = e.target.files[0]
			setProduct({ ...product, [ fieldName ]: fieldValue })
		}
		else {
			const fieldValue = e.target.value
			setProduct({ ...product, [ fieldName ]: fieldValue })
		}
	}

	const saveProduct = (e) => {
		setIsLoading(true)
		e.preventDefault();

		const formData = new FormData();
		for (let key in product) {
			formData.append(key, product[key])
		}
		formData.set("description", editorValue)

		if (product._id) {
			if (!(product.image instanceof File)) {
				delete product.image
			}

			updProduct
				.request(product._id, formData)
				.then(res => {
					if (res.status) {
						toast.success("Cập nhật sản phẩm thành công", { position: toast.POSITION.BOTTOM_LEFT})
						getProductsOfBrand.request(brand).then(data => {
							setProducts(data.result)
						})
						.finally(() => {
							setIsLoading(false)
						})
					}
					else {
						toast.error(res.result.message, { position: toast.POSITION.BOTTOM_LEFT })
					}

					setShowDialog(false)
					setProduct(initProduct)
					setEditorValue('')
				})
		}
		else {
			postProduct
				.request(formData)
				.then(res => {
					if (res.status) {
						getProductsOfBrand.request(brand).then(data => {
							setProducts(data.result)
						})
						.finally(() => {
							setIsLoading(false)
							toast.success("Thêm sản phẩm thành công", { position: toast.POSITION.BOTTOM_LEFT})
						})
					}
					else {
						setIsLoading(false)
						toast.error(res.result.message, { position: toast.POSITION.BOTTOM_LEFT })
					}
					setShowDialog(false)
					setProduct(initProduct)
					setEditorValue('')
				})
		}
	}

	const delProductHandle = (id) => {
		if (!window.confirm("Xác nhận xoá sản phẩm?")) {
			return;
		}
		setIsLoading(true)
		delProduct
			.request(id)
			.then(res => {
				if (res.status) {

					getProductsOfBrand.request(brand).then(data => {
						setProducts(data.result)
					})
					.finally(() => {
						setIsLoading(false)
						toast.success("Xoá sản phẩm thành công", { position: toast.POSITION.BOTTOM_LEFT})
					})
				}
				else {
					setIsLoading(false)
					toast.error(res.result.message, { position: toast.POSITION.BOTTOM_LEFT })
				}
			})
	}

	const updProductHandle = (id) => {
		setUpdate((prevState) => {
			return !prevState
		})

		getProduct.request(id).then(res => {
			let types = res.result.repair_type
			let brand_id = res.result.brand._id

			let temp_product = {...res.result, repair_type: types.map((item) => {return item._id;}), brand: brand_id}
			setProduct(temp_product)
			setEditorValue(temp_product.description)
		})

		handleDialog()
	}

	const handleChangeBrand = (e) => {
		setBrand(e.target.value)
	}

	const getRepairTypes = (types) => {
		let result = ""
		types.forEach((item) => {
			result += item.name + "+";
		});
		return result;
	}

	return (
		<>
		<div className="breadcrumb">
			<div className="container">
				<div className="breadcrumb-inner">
					<ul className="list-inline list-unstyled">
						{/* <li><a href="home.html">Home</a></li>
						<li className='active'>Price list</li> */}
					</ul>
				</div>
			</div>
		</div>
		{ isLoading && <Loading/> }

		<div className='container'>
			<div className='row'>
				<div className='col col-md-6'>
					<button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={handleDialog}>
						Thêm sản phẩm
					</button>
				</div>
				<div className='col col-md-6'>
					<div>
						<label className="info-title control-label">Chọn dòng điện thoại</label>
						<select className="form-control unicase-form-control selectpicker" onChange={handleChangeBrand}>
							<option value="">--Tất cả--</option>
							{ brands?.map((item, index) => 
							<option key={index} value={ item._id }>{ item.name }</option>
							) }
						</select>
					</div>
				</div>
			</div>
			<div className='row'>
				<div className="lbl-cnt"> <span className="lbl">Hiển thị </span>
					<div className="fld inline" style={{verticalAlign: "middle"}}>
						<div className={"dropdown dropdown-small dropdown-med dropdown-white inline" + (showRecords ? " open" : "")}>
							<button data-toggle="dropdown" type="button" className="btn dropdown-toggle" onClick={(e) => { setShowRecords(!showRecords) }} style={{backgroundColor: "#fff"}}> { records } 
								<span className="caret"></span> 
							</button>
							<ul role="menu" className="dropdown-menu" onMouseLeave={() => setShowRecords(false)}>
								<li role="presentation" style={{ padding: "3px 30px", cursor: "pointer" }} onClick={selectRecordsPerPage} >10</li>
								<li role="presentation" style={{ padding: "3px 30px", cursor: "pointer" }} onClick={selectRecordsPerPage} >25</li>
								<li role="presentation" style={{ padding: "3px 30px", cursor: "pointer" }} onClick={selectRecordsPerPage} >50</li>
								<li role="presentation" style={{ padding: "3px 30px", cursor: "pointer" }} onClick={selectRecordsPerPage} >100</li>
							</ul>
						</div>
					</div> <span className="lbl">mục</span>
				</div>
				<table className="table">
					<thead>
						<tr>
							<th scope="col">#</th>
							<th scope="col">Tên</th>
							<th scope="col">Loại sửa chữa</th>
							<th scope="col">Giá</th>
							<th scope="col">Hình ảnh</th>
							<th scope="col"></th>
						</tr>
					</thead>
					<tbody>
						{ productsShowing?.map((item, index) => 
							<tr key={index + 1}>
								<th scope="row">{index + 1}</th>
								<td><div className='update-product' onClick={() => updProductHandle(item._id)}>{ item.name }</div></td>
								<td>{ getRepairTypes(item.repair_type).split('+').map((item, i) => <p key={i}>{item}</p>)}</td>
								<td>{ item.price }K</td>
								<td><img src={item.image} alt="" className='img-50'/></td>
								<td className='grp-product-action'>
									<span className="delete-product icon fa-stack fa-lg" onClick={() => delProductHandle(item._id)}>
										<i className="fa fa-minus-circle fa-stack-1x fa-inverse"></i>
										<span className="tooltip">Xoá sản phẩm</span>
									</span>
								</td>
							</tr>
						) }
						
					</tbody>
				</table>

				<div className="clearfix filters-container bottom-row" style={{backgroundColor: "unset"}}>
					<div className="text-right">
						<div className="pagination-container">
							<ul className="list-inline list-unstyled">
								<li className="prev" style={{ backgroundColor: btnPrev ? "" : "#dddddd" }}><a href="!#" onClick={ (e) => { e.preventDefault(); if (currentPage > 1) selectPage(currentPage - 1); } }><i className="fa fa-angle-left"></i></a></li>
								<li><a href="!#"> {currentPage} / {numPages} </a></li>
								<li className="next" style={{ backgroundColor: btnNext ? "" : "#dddddd" }}><a href="!#" onClick={ (e) => { e.preventDefault(); if (currentPage < numPages) selectPage(currentPage + 1); } }><i className="fa fa-angle-right"></i></a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<ToastContainer />

			{/* <!-- Modal --> */}
			<div className="modal" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: showDialog ? "inline-grid" : "none"}}>
				<div className="modal-dialog" role="document">
					<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title" id="exampleModalLongTitle">THÊM SẢN PHẨM</h5>
					</div>
					<div className="modal-body" style={{ overflowY: "auto", maxHeight: "calc(100vh - 170px)" }}>
						<form>
							<div className="form-group row">
								<label htmlFor="inputName" className="col-sm-2 col-form-label">Tên</label>
								<div className="col-sm-10">
								<input type="text" className="form-control" id="inputName" placeholder="" name="name" onChange={handleCreateProduct} value={product.name}/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="inputImage" className="col-sm-2 col-form-label">Hình ảnh</label>
								<div className="col-sm-10">
								<input type="file" className="form-control" id="inputImage" ref={inputRef} name="image" onChange={handleCreateProduct}/>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="inputPrice" className="col-sm-2 col-form-label">Giá</label>
								<div className="col-sm-10">
									<input type="number" className="form-control" id="inputPrice" placeholder="" name="price" onChange={handleCreateProduct} value={product.price}/>
								</div>
							</div>

							<div className="form-group row">
								<label htmlFor="inputBrand" className="col-sm-2 col-form-label">Hãng</label>
								<div className="col-sm-10">
									<select className="form-control" id="inputBrand" name="brand" onChange={handleCreateProduct} value={product.brand}>
										<option></option>
										{ brands?.map((item, index) => 
										<option key={index} value={ item._id }>{ item.name }</option>
										) }
									</select>
								</div>
							</div>
							<div className="form-group row">
								<label htmlFor="inputRepair" className="col-sm-2 col-form-label">Loại sửa chữa</label>
								<div className="col-sm-10">
									{repairs?.map((item, index) => 
									<div key={index}>
										<label className="form-check-label">
											<RepairTypeCheckBox
												isCheck = {product.repair_type.some(ele => ele === item._id)}
												label = {item.name}
												repair_id = {item._id}
												setProduct = {setProduct}
											/>
										</label>
									</div>
									)}
								</div>
							</div>
							<div className='form-group row'>
								<label htmlFor="inputDescription" className="col-sm-2 col-form-label">Mô tả</label>
								<ReactQuill
									id='inputDescription'
									className='col-sm-10'
									value={editorValue}
									onChange={(value) => setEditorValue(value)}
									modules={{
										toolbar: [
											['bold', 'italic', 'underline', 'strike'],			// toggled buttons
											['blockquote', 'code-block'],
											['link', 'image'],

											// [{ 'header': 1 }, { 'header': 2 }],					// custom button values
											[{ 'list': 'ordered'}, { 'list': 'bullet' }],
											[{ 'script': 'sub'}, { 'script': 'super' }],		// superscript/subscript
											[{ 'indent': '-1'}, { 'indent': '+1' }],			// outdent/indent
											[{ 'direction': 'rtl' }],							// text direction

											// [{ 'size': ['small', false, 'large', 'huge'] }],	// custom dropdown
											[{ 'header': [1, 2, 3, 4, 5, 6, false] }],

											[{ 'color': [] }, { 'background': [] }],			// dropdown with defaults from theme
											[{ 'font': [] }],
											[{ 'align': [] }],

											['clean']											// remove formatting button
										]
									}}
									theme="snow"
								/>
							</div>
						</form>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDialog}>Đóng</button>
						<button type="button" className="btn btn-primary" onClick={saveProduct}>Lưu</button>
					</div>
					</div>
				</div>
			</div>
		</div>
		</>
	)
}

export default PriceList