export const expertslist = [
    {
        image: "assets/images/banners/home-banner1.jpg",
        text: "Mua bán - Sửa chữa"
    },
    {
        image: "assets/images/banners/home-banner3.jpg",
        text: "Ép kính lấy liền",
        link: "/category"
    },
    {
        image: "assets/images/banners/home-banner2.jpg",
        text: "Thay thế linh kiện",
        link: "/category"
    },
    {
        image: "assets/images/banners/home-banner.jpg",
        text: "Chạy phần mềm"
    },
    // {image: "assets/images/banners/home-banner4.jpg", text: ""}
]