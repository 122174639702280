import React, { useEffect, useMemo, useState } from 'react'
import Loading from '../loading/Loading';
import { useAPI } from '../../hooks/useAPI'
import repairAPI from '../../api/repair'
import MaterialReactTable from 'material-react-table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function RepairList(props) {
    const initRepair = {
        name: ""
    }

    const [ isLoading, setIsLoading ] = useState(false)
    const [ repairs, setRepairs ] = useState([])
    const getRepair = useAPI(repairAPI.getRepair)
    const getRepairs = useAPI(repairAPI.getRepairs)
    const deleteRepairs = useAPI(repairAPI.deleteMultiRepairs)
    const updateRepair = useAPI(repairAPI.updateRepair)
    const createRepair = useAPI(repairAPI.createRepair)

    const [rowSelection, setRowSelection] = useState({});
    const [ showDialog, setShowDialog ] = useState(false)
    const [ repair, setRepair ] = useState(initRepair)

    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: '#',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        },
        {
            accessorKey: 'name',
            header: 'Tên',
            accessorFn: (row) => <a onClick={() => updateRepairHandler(row._id)} style={{cursor: "pointer"}}>{row.name}</a>,
            size: 1000
        }
    ], [])

    useEffect(() => {
        collectRepairs()

        document.title = props.title
    }, [])

    const collectRepairs = () => {
        setIsLoading(true)
        getRepairs.request()
        .then(res => {
            let repairs = res.result.map((item, i) => ({...item, index: i + 1}))
            setRepairs(repairs)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const deleteHandler = () => {
        if (!window.confirm("Xác nhận xoá (các) Loại sửa chữa?")) {
            return;
        }

        deleteRepairs.request(Object.keys(rowSelection))
        .then(res => {
            if (res.status) {
                toast.success("Xoá (các) Loại sửa chữa thành công!", { position: toast.POSITION.TOP_RIGHT })
                collectRepairs()
                setRowSelection({})
            }
            else {
                toast.error("Có lỗi trong quá trình xoá (các) Loại sửa chữa. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    const updateRepairHandler = (repair_id) => {
        handleDialog()
        getRepair.request(repair_id)
        .then(res => {
            if (res.status) {
                setRepair(res.result)
            }
            else {
                toast.error("Có lỗi trong quá trình lấy thông tin Loại sửa chữa.", {position: toast.POSITION.TOP_RIGHT})
            }
        })
    }

    const handleDialog = () => {
        setShowDialog((prevState) => {
            if (prevState) {
                setRepair(initRepair)
            }
            return !prevState
        })
    }


    const handleCreateRepair = (e) => {
        const fieldName = e.target.name
        const fieldValue = e.target.value
        setRepair({ ...repair, [ fieldName ]: fieldValue })
    }

    const saveRepair = () => {
        if (!repair._id) {
            createRepair.request(repair)
            .then(res => {
                if (res.status) {
                    handleDialog()
                    collectRepairs()
                    toast.success("Thêm Loại sửa chữa thành công", { position: toast.POSITION.TOP_RIGHT })
                }
                else {
                    toast.error("Có lỗi trong quá trình tạo Loại sửa chữa", { position: toast.POSITION.TOP_RIGHT })
                }
            })
        }
        else {
            updateRepair.request(repair._id, {name: repair.name})
            .then(res => {
                if(res.status) {
                    handleDialog()
                    collectRepairs()
                    toast.success("Cập nhật thông tin Loại sửa chữa thành công.")
                }
                else {
                    toast.error("Có lỗi trong quá trình cập nhật thông tin Loại sửa chữa.")
                }
            })
        }
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            <li className='active'>Danh sách Loại sửa chữa</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
            { isLoading && <Loading/> }
            <div className="body-content">
                <div className='container'>
                    <div className='row'>
                        <div className='col col-md-6'>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={handleDialog}>
                            Thêm Loại sửa chữa
                        </button>
                    </div>
                    </div>
                    <div className='row m-b-20'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4' style={{textAlign: "right"}}>
                            <button className='btn btn-danger' onClick={deleteHandler}>Xoá</button>
                        </div>
                    </div>
                    <div className='row'>
                        <MaterialReactTable
                            columns={columns}
                            data={repairs}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [5, 10, 15, 20],
                                showFirstButton: false,
                                showLastButton: false,
                            }}
                            enableRowSelection={true}
                            enableSorting={true}
                            enableBottomToolbar={true}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableHeadCellProps={{
                                sx: {
                                    fontSize: '14px',
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    lineHeight: '30px'
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    fontSize: '14px',
                                },
                            }}
                            getRowId={(row) => row._id} //give each row a more useful id
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ rowSelection }} //pass our managed row selection state to the table to use
                        />
                    </div>
                </div>
            </div>
            {/* <!-- Modal --> */}
            <div className="modal" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: showDialog ? "inline-grid" : "none"}}>
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{repair._id ? "Sửa " : "Thêm"} Loại sửa chữa</h5>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="form-group row">
                                <label htmlFor="inputName" className="col-sm-2 col-form-label">Tên</label>
                                <div className="col-sm-10">
                                <input type="text" className="form-control" id="inputName" placeholder="" name="name" onChange={handleCreateRepair} value={repair?.name}/>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDialog}>Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={saveRepair}>Lưu</button>
                    </div>
                    </div>
                </div>
            </div>
            </>
    )
}

export default RepairList;
