import React from 'react';
import { Link } from 'react-router-dom';

const getRepairTypes = (types) => {
    let result = ""
    types.forEach((item, index) => {
        if (index < types.length - 1)
            result += item.name + " & ";
        else
            result += item.name;
    });
    return result;
}

function ProductItem(props) {
    return (
        <div className="item item-carousel" style={{width:'100%'}}>
            <div className="products">
                <div className="product">
                    <div className="product-image">
                        <div className="image"> 
                            <Link to={`/product/${props.product._id}`}>
                                <img src={props.product.image ? props.product.image : process.env.PUBLIC_URL + "/assets/images/products/default.png"} alt=""/>
                                {/* <img src={props.images[1]} alt="" className="hover-image"/> */}
                            </Link>
                        </div>
                    </div>
                    
                    <div className="product-info text-left outer-top-small">
                        <h3 className="name">
                            <Link to={`/product/${props.product._id}`} className="">
                                {getRepairTypes(props.product.repair_type) + " " + props.product.brand.name + " " + props.product.name}
                            </Link>
                        </h3>
                        <div className="rating rateit-small"></div>
                        <div className="description"></div>
                        <div className="product-price"> <span className="price">{Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(props.product.price + "000")} </span> </div>
                    </div>
                    <div className="cart clearfix animate-effect">
                        <div className="action">
                        <ul className="list-unstyled">
                            <li className="add-wishlist btn-group">
                                <button
                                    data-toggle="tooltip"
                                    className="btn icon"
                                    type="button"
                                    title="Add Wishlist"
                                >
                                    <i className="icon fa fa-heart"></i>
                                </button>
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    );
}

export default ProductItem;