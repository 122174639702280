import React, { useState } from 'react';
import TopNavigation from '../../sidebar/TopNavigation';

function AdminHome(props) {
    const [ openMenu, setOpenMenu ] = useState(false)
    return (
        <div className="body-content outer-top-vs" id="top-banner-and-menu">
            <div className="container">
                <div className="row">
                    <div className={openMenu ? "col-xs-12 col-sm-12 col-md-3 sidebar" : "btn btn-warning margin-15"}>
                        <TopNavigation openMenu={openMenu} setOpenMenu={setOpenMenu}/>
                    </div>
                    <div className={openMenu ? "col-xs-12 col-sm-12 col-md-9 homebanner-holder" : ""}>
                        <div className="container-fluid">
                            <button className='btn btn-success' style={{ margin: "0 0 40px 0" }}>
                                <i className='fa fa-plus-square'></i> Create
                            </button>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th className="col-xs-1 cart-romove item">#</th>
                                        <th className="col-xs-2 cart-romove item">Title</th>
                                        <th className="col-xs-1 cart-description item">Price</th>
                                        <th className="col-xs-1 cart-product-name item">Category</th>
                                        <th className="col-xs-3 cart-qty item">Description</th>
                                        <th className="col-xs-2 cart-sub-total item">Image</th>
                                        <th className="col-xs-1 cart-total last-item">Action</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                                    <tr>
                                        <td className='col-xs-1'>1</td>
                                        <td className='col-xs-2'>123</td>
                                        <td className='col-xs-1'>123</td>
                                        <td className='col-xs-1'>123</td>
                                        <td className='col-xs-3'>123</td>
                                        <td className='col-xs-2'>123</td>
                                        <td className="col-xs-1 romove-item">
                                            <button className='btn btn-warning' style={{ padding: "1px 6px", marginLeft: "5px" }}><i className="fa fa-pencil"></i></button>
                                            <button className='btn btn-danger' style={{ padding: "1px 6px", marginLeft: "5px" }}><i className="fa fa-trash-o"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AdminHome;