
const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return (re.test(email)) ? true : false
}

const validatePhoneNumber = (phone) => {
    // check the first character is 0
    if (Array.from(phone)[0] !== '0') {
        return false
    }

    // the correct lenght = 10
    if (phone.length !== 10) {
        return false
    }

    return true
}

const validatePassword = (password) => {
    if (password.length < 6) {
        return false
    }
    return true
}

const checkPwdMatch = (pwd, confirm_pwd) => {
    if (pwd !== confirm_pwd) {
        return false
    }
    return true
}

const exportObj = {
    validateEmail,
    validatePhoneNumber,
    validatePassword,
    checkPwdMatch
}

export default exportObj
