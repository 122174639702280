import client from './client'

const getBrands = () => client.get("/brands")
const getBrand = (id) => client.get("/brands/" + id, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const createBrand = (brand) => client.post("/brands", brand, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const updateBrand = (id, brand) => client.put("/brands/" + id, brand, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteAllBrand = () => client.delete("/brands", { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })
const deleteMultiBrands = (brands) => client.post("/brands/delete-brands", brands, { headers: { x_authorization: JSON.parse(sessionStorage.getItem("token")) } })

const exportedResult = {
    getBrands,
    getBrand,
    createBrand,
    updateBrand,
    deleteAllBrand,
    deleteMultiBrands
}

export default exportedResult;
