import React, { useEffect, useState } from 'react';
import productAPI from '../../api/products'
import { useAPI } from '../../hooks/useAPI';

function CategoryFilter(props) {
    const getBrands = useAPI(productAPI.getBrands)
    const [ brands, setBrands ] = useState([])
    useEffect(() => {
        getBrands.request().then(data => setBrands(data.result))
    }, [])
    return (
        <div className="sidebar-widget">
            <h3 className="section-title">Model</h3>
            {/* <div className="widget-header">
                <h4 className="widget-title">Category</h4>
            </div> */}
            <div className="sidebar-widget-body">
                <div className="accordion">
                    <div className="accordion-group">
                        <div className="accordion-heading"> <a className={`accordion-toggle` + (props.selectedCategory === false || props.selectedCategory === '' ? " active" : "") } onClick={(e) => { e.preventDefault(); props.setCategory(false); }}> TẤT CẢ </a> </div>
                    </div>
                    { brands?.map((item, index) => 
                    <div className="accordion-group" key={index}>
                        <div className="accordion-heading"> <a className={`accordion-toggle` + (props.selectedCategory._id === item._id ? " active" : "")} onClick={(e) => { e.preventDefault(); props.setCategory(item); }}> { item.name } </a> </div>
                    </div>
                    ) }
                </div>
                {/* <!-- /.accordion --> */}
            </div>
            {/* <!-- /.sidebar-widget-body --> */}
        </div>
    );
}

export default CategoryFilter;