export const features_list = [
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-01-1.png",
        title: "Chuyên nghiệp",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-02-1.png",
        title: "Thân thiện",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-03-1.png",
        title: "Không sửa không mất phí",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-04-1.png",
        title: "Uy tín",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-05.png",
        title: "Chuẩn đoán miễn phí",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-06.png",
        title: "Giá cả cạnh tranh",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-07.png",
        title: "Quy trình sửa nhanh chóng",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    },
    {
        image: "http://themelooks.net/demo/cosolu/wordpress/wp-content/uploads/2017/10/icon-08.png",
        title: "30 ngày bảo hành",
        content: "Lorem Ipsum is simply dummy text of the print been the industry's standard."
    }
]