import React, { useEffect, useMemo, useState } from 'react'
import Loading from '../../loading/Loading';
import { useAPI } from '../../../hooks/useAPI'
import userAPI from '../../../api/user'
import MaterialReactTable from 'material-react-table'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserList(props) {
    const initUser = {
        username: "",
        phoneNumber: "",
        name: "",
        password: ""
    }

    const [ isLoading, setIsLoading ] = useState(false)
    const [ users, setUsers ] = useState([])
    const getUser = useAPI(userAPI.getUser)
    const getUsers = useAPI(userAPI.getAllUsers)
    const deleteUsers = useAPI(userAPI.deleteUsers)
    const updateUser = useAPI(userAPI.updateUser)
    const createUser = useAPI(userAPI.createUser)

    const [ rowSelection, setRowSelection ] = useState({});
    const [ showDialog, setShowDialog ] = useState(false)
    const [ user, setUser ] = useState(initUser)
    const [ isChangePW, setIsChangePW ] = useState(false)

    const columns = useMemo(() => [
        {
            accessorKey: 'index',
            header: '#',
            size: 10,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        },
        {
            accessorKey: 'username',
            header: 'Username',
            accessorFn: (row) => <a onClick={() => updateUserHandler(row.username)} style={{cursor: "pointer"}}>{row.username}</a>,
            size: 200
        },
        {
            accessorKey: 'phoneNumber',
            header: 'Số điện thoại',
            size: 200
        },
        {
            accessorKey: 'status',
            header: 'Trạng thái',
            Cell: ({ cell }) => (
                <span className={`label label-${cell.getValue() === 1 ? 'success' : 'default'}`}>{cell.getValue() === 1 ? 'Hoạt động' : 'Không hoạt động'}</span>
            ),
            size: 200,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        },
        {
            accessorKey: 'role',
            header: 'Quyền',
            Cell: ({ cell }) => (
                <span className={`label label-${cell.getValue() === 1 ? 'danger' : 'primary'}`}>{cell.getValue() === 1 ? 'Admin' : 'User'}</span>
            ),
            size: 200,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            }
        }
    ], [])

    useEffect(() => {
        collectUsers()

        document.title = props.title
    }, [])

    const collectUsers = () => {
        setIsLoading(true)
        getUsers.request()
        .then(res => {
            let users = res.result.map((item, i) => ({...item, index: i + 1}))
            setUsers(users)
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    const deleteHandler = () => {
        if (!window.confirm("Xác nhận xoá (các) Người dùng?")) {
            return;
        }

        deleteUsers.request(Object.keys(rowSelection))
        .then(res => {
            if (res.status) {
                toast.success("Xoá (các) Người dùng thành công!", { position: toast.POSITION.TOP_RIGHT })
                collectUsers()
                setRowSelection({})
            }
            else {
                toast.error("Có lỗi trong quá trình xoá (các) Người dùng. Chi tiết (" + res.message + ")", { position: toast.POSITION.TOP_RIGHT })
            }
        })
    }

    const updateUserHandler = (username) => {
        handleDialog()
        getUser.request({ username: username })
        .then(res => {
            if (res.status) {
                setUser(res.result)
            }
            else {
                toast.error("Có lỗi trong quá trình lấy thông tin Người dùng.", {position: toast.POSITION.TOP_RIGHT})
            }
        })
    }

    const handleDialog = () => {
        setShowDialog((prevState) => {
            if (prevState) {
                setUser(initUser)
            }
            return !prevState
        })
    }


    const handleCreateUser = (e) => {
        const fieldName = e.target.name
        const fieldValue = e.target.value

        if (fieldName === "status") setUser({ ...user, status: user.status === 1 ? 0 : 1 })
        else if (fieldName === "role") setUser({ ...user, role: user.role === 1 ? 0 : 1 })
        else setUser({ ...user, [ fieldName ]: fieldValue })
    }

    const saveUser = () => {
        if (!user._id) {
            user.confirmPassword = user.password
            createUser.request(user)
            .then(res => {
                if (res.status) {
                    handleDialog()
                    collectUsers()
                    toast.success("Thêm Người dùng thành công", { position: toast.POSITION.TOP_RIGHT })
                }
                else {
                    toast.error("Có lỗi trong quá trình tạo Người dùng", { position: toast.POSITION.TOP_RIGHT })
                }
            })
        }
        else {
            updateUser.request(user)
            .then(res => {
                if(res.status) {
                    handleDialog()
                    collectUsers()
                    toast.success("Cập nhật thông tin Người dùng thành công.")
                }
                else {
                    toast.error("Có lỗi trong quá trình cập nhật thông tin Người dùng.")
                }
            })
        }
    }

    const handleIsChangePW = (e) => {
        setIsChangePW(prev => {
            return !prev
        })
    }

    return (
        <>
            <div className="breadcrumb">
                <div className="container">
                    <div className="breadcrumb-inner">
                        <ul className="list-inline list-unstyled">
                            <li className='active'>Danh sách Người dùng</li>
                        </ul>
                    </div>
                </div>
            </div>
            <ToastContainer />
            { isLoading && <Loading/> }
            <div className="body-content">
                <div className='container'>
                    <div className='row'>
                        <div className='col col-md-6'>
                        <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" onClick={handleDialog}>
                            Thêm Người dùng
                        </button>
                    </div>
                    </div>
                    <div className='row m-b-20'>
                        <div className='col-md-8'></div>
                        <div className='col-md-4' style={{textAlign: "right"}}>
                            <button className='btn btn-danger' onClick={deleteHandler}>Xoá</button>
                        </div>
                    </div>
                    <div className='row'>
                        <MaterialReactTable
                            columns={columns}
                            data={users}
                            enableColumnActions={false}
                            enableColumnFilters={false}
                            enablePagination={true}
                            muiTablePaginationProps={{
                                rowsPerPageOptions: [5, 10, 15, 20],
                                showFirstButton: false,
                                showLastButton: false,
                            }}
                            enableRowSelection={true}
                            enableSorting={true}
                            enableBottomToolbar={true}
                            enableTopToolbar={false}
                            muiTableBodyRowProps={{ hover: false }}
                            muiTableHeadCellProps={{
                                sx: {
                                    fontSize: '14px',
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    lineHeight: '30px'
                                },
                            }}
                            muiTableBodyCellProps={{
                                sx: {
                                    borderRight: '2px solid #e0e0e0', //add a border between columns
                                    fontSize: '14px',
                                },
                            }}
                            getRowId={(row) => row._id} //give each row a more useful id
                            onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                            state={{ rowSelection }} //pass our managed row selection state to the table to use
                        />
                    </div>
                </div>
            </div>
            {/* <!-- Modal --> */}
            <div className="modal" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{display: showDialog ? "inline-grid" : "none"}}>
            <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{user._id ? "Sửa " : "Thêm"} Người dùng <b>{ user.username ? "[" + user.username + "]" : ""}</b></h5>
                    </div>
                    <div className="modal-body">
                        <form>
                            {(!user._id) ?
                            <div className="form-group row">
                                <label htmlFor="inputUsername" className="col-sm-2 col-form-label">User Name</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputUsername" placeholder="" name="username" onChange={handleCreateUser} value={user?.username}/>
                                </div>
                            </div>
                            : "" }
                            <div className="form-group row">
                                <label htmlFor="inputPhoneNumber" className="col-sm-2 col-form-label">Số điện thoại</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPhoneNumber" placeholder="" name="phoneNumber" onChange={handleCreateUser} value={user?.phoneNumber}/>
                                </div>
                            </div>
                            {user._id ? 
                            <div className="form-group row">
                                <label htmlFor="isChangePW" className="col-sm-2 col-form-label">Thay đổi mật khẩu?</label>
                                <div className="col-sm-10">
                                    <input type="checkbox" id="isChangePW" onChange={handleIsChangePW}/>
                                </div>
                            </div>
                            : "" }
                            {isChangePW || (!user._id) ?
                            <div className="form-group row">
                                <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Mật khẩu</label>
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" id="inputPassword" placeholder="" name="password" onChange={handleCreateUser} value={user?.password}/>
                                </div>
                            </div>
                            : ""}
                            <div className="form-group row">
                                <label htmlFor="inputStatus" className="col-sm-2 col-form-label">Trạng thái</label>
                                <div className="col-sm-10">
                                    <div className='col-sm-6'>
                                        <input type="checkbox" id="inputStatus" name="status" onChange={handleCreateUser} checked={ user.status === 1 ? true : false }/>
                                        <label htmlFor="inputStatus">Hoạt động</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputRoleUser" className="col-sm-2 col-form-label">Quyền</label>
                                <div className="col-sm-10">
                                    <div className='col-sm-3'>
                                        <input type='radio' id="inputRoleUser" name="role" onChange={handleCreateUser} checked={user.role === 0 ?? true}/>
                                        <label htmlFor='inputRoleUser'>User</label>
                                    </div>
                                    <div className='col-sm-3'>
                                        <input type='radio' id="inputRoleAdmin" name="role" onChange={handleCreateUser} checked={user.role === 1 ?? true}/>
                                        <label htmlFor='inputRoleAdmin'>Admin</label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleDialog}>Đóng</button>
                        <button type="button" className="btn btn-primary" onClick={saveUser}>Lưu</button>
                    </div>
                    </div>
                </div>
            </div>
            </>
    )
}

export default UserList;
