export const ErrorList = {
    "invalid_email": "Email không hợp lệ",
    "not_match_pwd": "Xác nhận mật khẩu không đúng",
    "invalid_password": "Sử dụng 6 kí tự trở lên cho mật khẩu của bạn",
    "invalid_phone_number": "Số điện thoại phải bắt đầu bằng 0 và có 10 số",
    "invalid_name_blank": "Tên không được để trống",
    "invalid_phone_number_blank": "Số điện thoại không được để trống",
    "invalid_subject_blank": "",
    "invalid_message_blank": "Vấn đề không được để trống",
}
